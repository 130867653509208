<template>
  <div
    :class="classes"
    :style="{ '--accent-color': color }"
  >
    <div @click="emit('open:expert', expert?.code)">
      <NuxtLazyHydrate :on-interaction="['mousedown', 'touchstart']">
        <MPopover
          :position="position"
          :max-width="300"
          :ignore-scroll="ignoreScroll"
          inner-element-scroll-class="product-expert__body"
          with-close
        >
          <template #activator>
            <div class="product-expert__popover-activator">
              <span>{{ expert?.code }}</span>
              <span>{{ expert?.value }}</span>
            </div>
          </template>
          <template #content>
            <div class="product-expert__popover-content text-editor">
              <div>
                {{ expert?.name }}
              </div>
              <div class="product-expert__body" v-html="DOMPurify.sanitize(expertInfo ?? '')" />
              <div>
                {{ $t("molecules.product-expert.scale") }} — {{ expert?.minValue }}-{{ expert?.maxValue }}
              </div>
            </div>
          </template>
        </MPopover>
      </NuxtLazyHydrate>
      <div class="product-expert__body">
        <p ref="expertNameRef">
          {{ expert?.name }}
        </p>
        <p v-if="description">
          {{ description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineComponent, ref, toRefs, computed } from 'vue'
import DOMPurify from 'isomorphic-dompurify'

import type { PropType } from 'vue'
import type { ExpertRate } from '@/modules/nuxt-api/models/Product'

import MPopover from '@/components/molecules/Popover/MPopover.vue'

export type ProductExpertVariant = 'rounded' | undefined

defineComponent({ name: 'MProductExpert' })

const props = defineProps({
  expert: {
    type: Object as PropType<ExpertRate>,
    required: true
  },
  variant: {
    type: String as PropType<ProductExpertVariant>,
    default: undefined
  },
  expertInfo: {
    type: String,
    default: undefined
  },
  position: {
    type: String as PropType<'bottom-start' | 'top-end' | 'bottom-end' | 'bottom'>,
    default: 'bottom-start'
  },
  ignoreScroll: Boolean
})

const emit = defineEmits(['open:expert'])

const {
  expert,
  variant
} = toRefs(props)

const description = computed(() => expert?.value?.featuresList.join(', ') ?? '')

const expertNameRef = ref<HTMLElement>()
if (expertNameRef.value !== undefined && description.value) {
  expertNameRef.value?.classList.add('overflowed')
}

const classes = computed(() => {
  const list = ['product-expert']

  if (variant?.value) { list.push(`product-expert--${variant.value}`) }
  // if (percent.value < 0.5 && variant?.value !== 'rounded') { list.push('product-expert--reversed') }

  return list
})

const color = computed(() =>
  expert?.value?.colorHex.length > 0 ? expert.value.colorHex : '#dfbbbb'
)
</script>

<style lang="postcss">
@import url("assets/styles/mixins/text-editor.pcss");

.product-expert {
  --product-expert-bg: transparent;
  --product-expert-width: 100%;
  --product-expert-code-display: none;
  --product-expert-score-content: "";
  --product-expert-info-display: block;
  --product-expert-badge-padding: 0 var(--spacer-4xs);
  --product-expert-score-width: 100%;
  --product-expert-badge-position: absolute;

  position: relative;
  width: 100%;

  & .popover {
    user-select: none;

    @media (--screen-xs) {
      bottom: calc(var(--spacer-3xs) * -1);
    }
  }

  & .product-expert__popover-activator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border: 0.125rem solid var(--accent-color);
    border-radius: var(--border-radius-full);
    background-color: var(--product-expert-bg);
    color: var(--color-text-dark);
    user-select: none;

    & span {
      z-index: 1;
    }

    & span:first-child {
      display: var(--product-expert-code-display);
    }

    & span:nth-child(2) {
      text-align: left;
    }

    & span:nth-child(3) {
      display: var(--product-expert-info-display);
      color: var(--color-text-middle);

      @mixin text-xs;
    }

    @mixin text-base-semibold;
  }

  & > div:first-child {
    position: relative;
    display: flex;
    gap: var(--spacer-2xs);

    @media (hover: hover) and (--screen-lg) {
      &:hover {
        cursor: pointer;
      }
    }
  }

  & > div:first-child > div:last-child {
    display: var(--product-expert-info-display);
    width: 100%;

    & > p:last-child {
      overflow: hidden;
      width: 100%;
      color: var(--color-text-dark);
      font-weight: 500;
      white-space: nowrap;

      @mixin text-base;

      @media (--screen-xs) {
        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 12px;
          background: linear-gradient(90deg, rgb(255 255 255 / 0%) 1.79%, #FFF 49.36%);
        }
      }

    }

    & > p:first-child {
      overflow: hidden;
      color: var(--color-text-middle);
      white-space: nowrap;

      @mixin text-xs;
    }
  }

  &__popover-content {
    overflow-x: hidden;
    overflow-y: auto;
    user-select: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    & > div:first-child {
      margin-right: var(--spacer-2xl);
      margin-bottom: var(--spacer-3xs);

      @mixin text-base-bold;

      @media (--screen-xs) {
        @mixin text-lg;
      }
    }

    & a {
      display: block;
      width: fit-content;
      margin: var(--spacer-4xs) 0;
      word-break: break-word;
    }

    & > div:last-child {
      margin-top: var(--spacer-3xs);
      color: var(--color-neutral-600);
    }

    @mixin text-sm;

    @media (--screen-xs) {
      @mixin text-base;
    }
  }

  &--active {
    --product-expert-bg: var(--accent-color);

    & .product-expert__popover-activator {
      color: var(--color-white);

      & > span:nth-child(3) {
        color: var(--color-white);
      }
    }
  }

  &--reversed {
    & > div:first-child {
      justify-content: flex-end;

      & > div:first-child {
        text-align: left;

        & > p:last-child {
          max-width: calc((1 - var(--percent)) * 100%);
          padding-right: 0;
        }
      }
    }
  }

  @mixin text-sm;

  @media (hover: hover) and (--screen-lg) {
    &:hover {
      --product-expert-bg: var(--accent-color);

      & .product-expert__popover-activator {
        color: var(--color-white);

        & > span:nth-child(3) {
          color: var(--color-white);
        }
      }
    }
  }
}

.product-expert--rounded {
  --product-expert-width: 0;
  --product-expert-badge-padding: var(--spacer-4xs);
  --product-expert-info-display: none;
  --product-expert-badge-position: static;
  --product-expert-score-content: none;
  --product-expert-score-width: var(--spacer-lg);

  margin-right: 0;

  & .product-expert__popover-activator {
    gap: 0.2rem;
    width: var(--spacer-lg);
    height: var(--spacer-lg);
    padding: var(--product-expert-badge-padding);
    transform: none;

    &:not(:hover) {
      background: var(--color-white);
    }

    @mixin text-sm-semibold;

    @media (--screen-xs) {
      & > span:nth-child(2) {
        @mixin text-xs-semibold;
      }
    }

    @media (--screen-lg) {
      width: auto;
      height: auto;
    }
  }

  @media (--screen-lg) {
    --product-expert-badge-padding: var(--spacer-5xs) 0.375rem;
    --product-expert-code-display: inline;
  }
}

.product-expert {
  &__body {
    overflow: auto;
    max-height: 300px;
    padding-right: var(--spacer-3xs);

    &::-webkit-scrollbar-track {
      border-radius: var(--border-radius-2xs);
      background-color: var(--color-neutral-100);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: var(--border-radius-2xs);
      background-color: var(--color-neutral-500);
    }

    @media (--screen-xs) {
      max-height: calc(70vh - 5.5rem);
      padding-right: 0;
    }
  }
}
</style>
